import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const About = ({ location }) => {

  return (
    <Layout location={location} title="このサイトについて">
      <Seo title="About This Site | pilefort" />
      <h1>管理しているサイト一覧</h1>
      <h1>技術ブログ (本サイト)</h1>
      <p>不定期で技術ネタをポストします。</p>
      <a href='https://pilefort.dev' target='_blank' rel="noreferrer">https://pilefort.dev</a>
      <h1>スニペット集</h1>
      <p>現在閉鎖中、簡単にスニペッと追加できる仕組みを考えてるところ</p>
    </Layout>
  )
}

export default About
